import { createSelector } from '@reduxjs/toolkit'
import { selectGroups } from "../slices/groups"
import { selectResourceHierarchy } from "../slices/chargeability"

export const selectUserName = (_, userName) => userName

export const selectMyTeams = createSelector(
  selectGroups,
  selectUserName,
  (groups, userName) => {
    const myTeams = []
    if (Array.isArray(groups)) {
      groups.forEach(group => {
        const { teams = [] } = group
        teams.forEach(team => {
          if (team.manager === userName) { myTeams.push(team.name) }
        })
      })
    }
    return myTeams
  }
)

export const selectMyTeamMembers = createSelector(
  selectMyTeams,
  selectResourceHierarchy,
  selectUserName,
  (myTeams, resourceHierarchy, userName) => {
    const myTeamMembers = []
    if (resourceHierarchy) {
      Object.values(resourceHierarchy).forEach(group => {
        Object.entries(group).forEach(([teamName, teamMembers]) => {
          if (myTeams.includes(teamName)) { myTeamMembers.push(...teamMembers) }
        })
      })
    }
    return myTeamMembers.filter(el => el !== userName)
  }
)
