import ToggleSwitch from '../primitives/ToggleSwitch'
import ChargeabilityTimeframeSlider from '../views/ChargeabilityTimeframeSlider'
import ChargeabilityResourceLevelSlider from '../views/ChargeabilityResourceLevelSlider'
import { Box } from '@mui/material'

const ChargeabilityControls = ({
  timeFrame,
  onTimeFrameChanged,
  resourceLevel,
  onResourceLevelChanged,
  adjustForLeave,
  onAdjustForLeaveChanged,
  showColours,
  onShowColoursChanged,
  showExEmployees,
  onShowExEmployeesChanged,
}) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center' }}>
      <ChargeabilityTimeframeSlider timeframe={timeFrame} onChange={onTimeFrameChanged} />
      <ChargeabilityResourceLevelSlider
        resourceLevel={resourceLevel}
        onChange={onResourceLevelChanged}
        sx={{ mr: 12 }}
      />
      <ToggleSwitch
        checked={adjustForLeave}
        onChange={(event) => onAdjustForLeaveChanged(event.target.checked)}
        offText=""
        onText="LEAVE ADJUSTED"
        sx={{ float: 'left', mr: 2, mt: 0.5, width: 'fit-contents' }}
      />
      <ToggleSwitch
        checked={showColours}
        onChange={(event) => onShowColoursChanged(event.target.checked)}
        offText=""
        onText="COLOURS"
        sx={{ float: 'left', mr: 2, mt: 0.5, width: 'fit-contents' }}
      />
      {resourceLevel === 'person' && (
        <ToggleSwitch
          checked={showExEmployees}
          onChange={(event) => onShowExEmployeesChanged(event.target.checked)}
          offText=""
          onText="EX-EMPLOYEES"
          sx={{ float: 'left', ml: 7, mr: 2, mt: 0.5, width: 'fit-contents' }}
        />
      )}
    </Box>
  )
}
export default ChargeabilityControls
