import save from 'save-file'
import { Button } from '@mui/material'

import ExportIcon from '../primitives/ExportIcon'
import { generateCsvFromChargeability } from '../utils/chargeabilityUtils'
import colours from '../styles/colours'

const ChargeabilityExportButton = ({
  aggregatedStructures,
  resourceLevel,
  timeFrame,
}) => {
  const handleExportClick = () => {
    const csvOutput = generateCsvFromChargeability(
      resourceLevel,
      aggregatedStructures
    )
    save(csvOutput, `Work analysis by ${resourceLevel} over ${timeFrame}.CSV`)
  }

  return (
    <Button
      onClick={handleExportClick}
      size="small"
      sx={{
        backgroundColor: colours.backgroundDefault,
        borderRadius: 1,
        color: colours.awaBlueOld,
        float: 'right',
        minWidth: 80,
        mt: 0.5,
        ml: 'auto'
      }}
      variant="text"
    >
      <ExportIcon
        fill={colours.awaBlueOld}
        height={20}
        sx={{ fontSize: 22, mr: 1 }}
        width={20}
      />
      Export
    </Button>
  )
}
export default ChargeabilityExportButton
